<template>
  <div class="bg-bg_alt_color rounded-md mt-2">
    <v-expansion-panels v-model="expansionPanelValue">
      <v-expansion-panel>
        <v-expansion-panel-title>
          {{ t('order_evaluation') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="p-2">
            <MyAccountPlacesTextareaField
              v-if="(disableRating && note.length) || !disableRating"
              v-model="note"
              rows="1"
              :disabled="disableRating"
              class="h-[65px]"
              class-text="sela-text-field small"
              :placeholder="t('rate')"
              error=""
            />
            <SelaResponseError
              class="my-2 text-xs"
              api-key="rate_bill"
              :with-message="false"
              :except="['note']"
            />
            <div class="flex">
              <RikazStarRating
                v-model="stars"
                :disabled="disableRating"
                :size="20"
                color-not-filled="text-bg_color"
                class="brightness-90"
                :length="5"
              />
            </div>
            <div v-if="!disableRating" class="flex justify-end mt-2">
              <SelaSubmitButton
                :title="t('save')"
                type="button"
                width="w-max"
                :loading="loadingRate"
                :disabled="stars == 0"
                @click="rate"
              />
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  order: UserOrder
}>()
const { loadingRate, rateBill } = useRateBill(props.order.id)
const note = ref<string>('')
const stars = ref(props.order.rating || 0)
const disableRatingLocal = ref(false)
const expansionPanelValue = ref<number | undefined>(
  props.order.rating ? 0 : undefined
)
async function rate() {
  if (await rateBill(stars.value, note.value)) {
    disableRatingLocal.value = true
  }
}

const disableRating = computed(() => {
  return (props.order.rating || 0) > 0 || disableRatingLocal.value
})
const { t } = useI18n()
</script>
