<template>
  <div class="p-3 bg-bg_alt_color rounded-md sm:min-w-[400px]">
    <MyAccountOrdersCardInformationOrder
      :bill="order"
      :show-number-bill="expansionBillDetails"
    />
    <v-expansion-panels v-model="expansionPanelValue">
      <v-expansion-panel>
        <v-expansion-panel-title>
          {{ t('orders_details') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div
            class="p-2 border bg-nav_color bg-opacity-5 rounded-b border-t-transparent border-sela-light"
          >
            <MyAccountOrdersCardMaterial
              v-for="billDetail in order.bill_details_withOut_offers"
              :key="billDetail.id"
              :branch-id="order.branch_id"
              :item="billDetail"
            />
            <MyAccountOrdersCardOffer
              v-for="(item, key) in order.bill_details_offers"
              :key="`bill-item-offer-${key}`"
              :branch-id="order.branch_id"
              :offer-items="item"
            />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <MyAccountOrdersCardDeliveringCode
      :order="order"
    />
    <MyAccountOrdersCardRate
      v-if="order.stringState === 'delivered'"
      class="mt-3"
      :order="order"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    order: UserOrder
    showNumberBill?: boolean
    expansionBillDetails?: boolean
  }>(),
  {
    showNumberBill: true,
    expansionBillDetails: false
  }
)
const expansionPanelValue = ref<number | undefined>(
  props.expansionBillDetails ? 0 : undefined
)
const { t } = useI18n()
</script>
